import React, { useEffect, useState } from "react";
import gsap from "gsap";
import styled from "styled-components";

const StyledImageSlider = styled.div`
  html,
  body {
    padding: 0;
    margin: 0;
    overflow: hidden;
    font-family: "Sen";
  }

  * {
    box-sizing: border-box;
    outline: none;
    -webkit-tap-highlight-color: transparent;
    cursor: none;
    user-select: none;
    -webkit-user-drag: none;
  }

  #main {
    display: flex;
  }

  .part {
    flex: 1;

    .section {
      width: 100%;
      height: 100vh;
      position: relative;
      overflow: hidden;

      img {
        width: 100vw;
        height: 100vh;
        object-fit: cover;
        position: absolute;
        left: var(--x);
        pointer-events: none;
      }
    }
  }

  .cursor {
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    background: white;
    position: absolute;
    z-index: 999;
    pointer-events: none;
    mix-blend-mode: difference;

    &-f {
      width: var(--size);
      height: var(--size);
      position: absolute;
      top: 0;
      left: 0;
      background-image: url("data:image/svg+xml,%3Csvg width='47' height='47' viewBox='0 0 47 47' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M42.4202 42.4202C38.8403 46 33.3594 46 23.5 46C13.6406 46 8.15966 46 4.57983 42.4202C1 38.8403 1 33.3594 1 23.5C1 13.6406 1 8.15966 4.57983 4.57983C8.15966 1 13.6406 1 23.5 1C33.3594 1 8.15966 1 42.4202 4.57983C46 8.15966 46 13.6406 46 23.5C46 33.3594 46 38.8403 42.4202 42.4202Z' stroke='white'/%3E%3C/svg%3E%0A");
      background-size: cover;
      mix-blend-mode: difference;
      pointer-events: none;
      opacity: 0.5;
    }
  }

  .buttons {
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 99;

    button {
      border: none;
      background-size: contain;
      background: url("data:image/svg+xml,%3Csvg width='10' height='29' viewBox='0 0 10 29' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 0V27L1 17.4857' stroke='white' stroke-width='2' /%3E%3C/svg%3E%0A")
        no-repeat;
      background-position: center;
      width: 10px;
      height: 30px;
      display: block;
      margin: 20px 0;
      padding: 0 15px;
      transition-duration: 0.6s;

      &.next {
        transform: scaleY(-1);
      }

      &.prev:active {
        transform: translateY(8px);
      }

      &.next:active {
        transform: scaleY(-1) translateY(8px);
      }
    }
  }

  h1 {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: auto;
    z-index: 99;
    color: white;
    text-align: center;
    font-size: 2.6em;
    mix-blend-mode: overlay;
    pointer-events: none;
  }

  .content {
    width: 90%;
    position: absolute;
    bottom: 20px;
    text-align: center;
    left: 0;
    right: 0;
    margin: auto;
    color: white;
    z-index: 99;
    font-size: 0.8em;

    p {
      margin: 0.5em auto;
    }

    kbd {
      width: 15px;
      height: 15px;
      border: 1px solid white;
      display: inline-block;
      border-radius: 3px;
      font-size: 0.9em;
      vertical-align: text-top;
    }

    a {
      color: rgba(227, 227, 227, 0.78);
      text-decoration: none;
      border-bottom: 1px solid currentColor;

      &:hover {
        padding-bottom: 1px;
      }
    }
  }
`;

const ImageSlider = () => {
  const cols = 3;
  const [parts, setParts] = useState([]);
  const images = [
    "https://images.unsplash.com/photo-1549880338-65ddcdfd017b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2550&q=80",
    "https://images.unsplash.com/photo-1544198365-f5d60b6d8190?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2550&q=80",
    "https://images.unsplash.com/photo-1493246507139-91e8fad9978e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2700&q=80",
  ];
  const [current, setCurrent] = useState(0);
  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    // Preload images
    images.forEach((imageUrl) => {
      const image = new Image();
      image.src = imageUrl;
    });

    // Create and initialize the parts
    const newParts = [];
    for (let col = 0; col < cols; col++) {
      newParts.push(
        <div className="part" key={col}>
          <div className="section">
            <img src={images[current]} alt="slider-image" />
          </div>
        </div>
      );
    }
    setParts(newParts);
  }, [current]);

  const animOptions = {
    duration: 2.3,
    ease: "power4.inOut",
  };

  const go = (dir) => {
    if (!playing) {
      setPlaying(true);
      const nextCurrent = current + dir;
      setCurrent(
        nextCurrent < 0
          ? images.length - 1
          : nextCurrent >= images.length
          ? 0
          : nextCurrent
      );

      // Animation logic here
      const animateParts = async () => {
        const updatedParts = [...parts];
        for (let p in updatedParts) {
          const part = updatedParts[p];
          updatedParts[p] = (
            <div className="part" key={p}>
              <div className="section">
                <img src={images[current]} alt="slider-image" />
              </div>
            </div>
          );
        }
        setParts(updatedParts);
        setTimeout(() => {
          setPlaying(false);
        }, animOptions.duration * 1000);
      };

      animateParts();
    }
  };

  // Event listeners
  const handleKeyDown = (e) => {
    if (["ArrowDown", "ArrowRight"].includes(e.key)) {
      go(1);
    } else if (["ArrowUp", "ArrowLeft"].includes(e.key)) {
      go(-1);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [current]);

  return (
    <div id="main">
      {parts}
      <h1>something</h1>
      <div className="content">
        <p>
          You can press <kbd>▲</kbd> <kbd>▼</kbd> on your keyboard or swipe
          up/down to navigate. Mouse wheel works too.
        </p>
        <p>
          <a href="https://codepen.io/theseventh" target="_blank">
            codepen
          </a>{" "}
          //{" "}
          <a href="https://twitter.com/the_seventh_kek" target="_blank">
            twitter
          </a>
        </p>
      </div>
      <div className="buttons">
        <button className="next" onClick={() => go(-1)}></button>
        <button className="prev" onClick={() => go(1)}></button>
      </div>
    </div>
  );
};

export default ImageSlider;

