import React from 'react'
import './CardSlider.css'
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper modules
import SwiperCore, { EffectCoverflow, Pagination, Keyboard, Mousewheel } from 'swiper/core';

// Install Swiper modules


const CardSlider = () => {

  return (
    <section id="card-slider" className='details-revert'> 
      <Swiper
        effect="coverflow"
        grabCursor={true}
        centeredSlides={true}
        slidesPerView="auto"
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 3,
          slideShadows: true,
        }}
        keyboard={{
          enabled: true,
        }}
        mousewheel={{
          thresholdDelta: 70,
        }}
        spaceBetween={60}
        loop={true}
        pagination={{
          el: ".swiper-pagination",
          clickable: true,
        }}
        className="swiper"
      >
        <SwiperSlide className="first-letter swiper-slide--one">
          <span>Bautizos</span>
          <div>
            <h2>Celebrar la llegada de un nuevo miembro a la familia</h2>
            <p>
              <svg
                xmlns="http=//www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                />
              </svg>
              San Mateo Atenco, Méx.
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide className="first-letter swiper-slide--two">
          <span>Eventos</span>
          <div>
            <h2>Ya sea una reunión íntima o una gran celebración</h2>
            <p>
              <svg
                xmlns="http=//www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                />
              </svg>
              San Mateo Atenco, Méx.
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide className="first-letter swiper-slide--three">
          <span>Graudaciones</span>
          <div>
            <h2>Celebra tus logros académicos</h2>
            <p>
              <svg
                xmlns="http=//www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                />
              </svg>
              San Mateo Atenco, Méx.
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide className="first-letter swiper-slide--four">
          <span>Bodas</span>
          <div>
            <h2>Haz de tu día especial un cuento de hadas hecho realidad</h2>
            <p>
              <svg
                xmlns="http=//www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                />
              </svg>
              San Mateo Atenco, Méx.
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide className="first-letter swiper-slide--five">
          <span>XV Años</span>
          <div>
            <h2>Los XV años son un hito en la vida de una joven</h2>
            <p>
              <svg
                xmlns="http=//www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                />
              </svg>
              San Mateo Atenco, Méx.
            </p>
          </div>
        </SwiperSlide>
        <div class="swiper-pagination"></div>
      </Swiper>
    </section>
  );
}

export default CardSlider