const frontPageImages = [
      {
      "text":"slider-1",
      "image":require('../images/A2FEE04.jpg')
      },
      {
      "text":"slider-2",
      "image":`require('../images/IMG_0666.jpg')`
      }
]


export default frontPageImages;