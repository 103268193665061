import React, {useEffect, useState} from 'react'
import Navbar from '../componets/Navbar';
import CarouselImage from '../componets/CarouselImage';
import BoxCard from '../componets/BoxCard';
import BarButton from '../componets/BarButton';
import { InstagramEmbed } from "react-social-media-embed";
import AOS from 'aos';
import 'aos/dist/aos.css'
import '../assests/css/front-page.css'
import ImageSlider from '../componets/ImageSlider';
import CardSlider from '../componets/Slider/CardSlider';
import ReactPlayer from "react-player";
import InstagramQRCodeGenerator from '../componets/QRGenerator/InstagramQRCodeGenerator';
import Icon from '../componets/Icon';
import GridTemplate from '../componets/Grid/GridTemplate';

const FrontPage = () => {

  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() =>{
    setIsPlaying(true);
  },[])


  return (
    <>
      <Navbar />

      <section className="hero-section relative">
        <h1 className="hero-title absolute top-[30%] z-10 text-inherit font-bold text-xl md:text-8xl">
          Salon y Jardin de Eventos Sociales
        </h1>
        
        
      <div className='h-full'>
      {isPlaying && (
        <video autoPlay muted playsInline loop controls={false} className='w-auto  max-w-[999999px] md:w-full h-[100vh] md:h-full´'>
        <source src="../../assets/video/Front-Page.mp4" type="video/mp4" />
      </video>
      )}
      </div>
      </section>
      <section id="Eventos" className="details flex flex-wrap">
        <div className="container-right flex flex-wrap bg-opacity-60">
          <div className="image-container h-96 md:w-7/12 relative">
            <div className="absolute h-full w-full flex justify-center items-center bg-opacity-10 bg-slate-400">
              <h1 className="uppercase underline text-[#8F5E36]">Eventos</h1>
            </div>
            <img
              className="h-full w-full object-cover object-center"
              src={"../assets/images/j-1.jpg"}
              alt=""
            />
          </div>
          <div className="realtive text-container p-2 md:px-24 flex flex-col justify-center items-center md:w-5/12">            
            <h4 className="text-center text-xl">
              SALON Y JARDIN PARA EVENTOS SOCIALES... BODAS, XV AÑOS, BAUTIZOS,
              PRIMERA COMUNION, FIESTA DE GRADUACION, EVENTOS POLITICOS,
              CONFERENCIAS, ETC.
            </h4>
          </div>
        </div>
        <div className="container-left bg-[#121C21] flex flex-wrap-reverse">
          <div className="text-container p-3 md:px-24 flex flex-col justify-center items-center md:w-5/12">
            <h4 className="text-center text-xl">
             Jardín de eventos, con áreas para recepción, ceremonias al aire libre, pista de baile y zona de descanso, ofrece una experiencia única. También contamos con un salón cubierto para adaptarse a diversas celebraciones, desde bodas hasta eventos corporativos.
            </h4>
          </div>
          <div className="image-container h-96 md:w-7/12 relative">
            <div className="absolute h-full w-full flex justify-center items-center bg-opacity-10 bg-slate-400">
              <h1 className="uppercase underline text-[#8F5E36]">Areas</h1>
            </div>
            <img
              className="h-full w-full object-cover object-center"
              src={"../assets/images/Jardin.jpg"}
              alt=""
            />
          </div>
        </div>
        <div className="container-right flex flex-wrap w-full">
          <div className="image-container h-96 md:w-7/12 relative">
          <div className="absolute flex md:hidden h-full w-full flex justify-center items-center bg-opacity-10 bg-slate-400">
              <h1 className="uppercase underline text-[#8F5E36]">Servicios</h1>
            </div>
            <img
              className="h-full w-full  object-cover object-center"
              src={"../assets/images/j3.jpg"}
              alt=""
            />
          </div>
          <div className="text-container p-3 md:px-24 flex flex-col justify-center items-center w-full md:w-5/12">
          <h1 className="hidden md:flex uppercase underline text-black">Servicios</h1>
            <h4 className='flex md:hidden text-lg md:text-lg text-justify md:text-center'>
                Ofrecemos una amplia gama de servicios para hacer de tu evento una experiencia inolvidable. Nuestro equipo se encarga de cada detalle. Disfruta de catering personalizado, servicios de decoración, equipo técnico de alta calidad y mucho más. Nos esforzamos por proporcionar un ambiente único y adaptado a tus necesidades.
          </h4>
          </div>
        </div>
      </section>
      <section id='quotes' className='hidden md:flex w-full h-auto md:h-[200px] p-5 md:p-20 bg-white'>
          <h3 className='text-lg md:text-2xl text-justify md:text-center'>
                Ofrecemos una amplia gama de servicios para hacer de tu evento una experiencia inolvidable. Nuestro equipo se encarga de cada detalle. Disfruta de catering personalizado, servicios de decoración, equipo técnico de alta calidad y mucho más. Nos esforzamos por proporcionar un ambiente único y adaptado a tus necesidades.
          </h3>
      </section>
      
      <section className='details'>
        <CardSlider />
      </section>
      <section className=''>
        <GridTemplate/>
      </section>
      <section id="Ubicación" className="map-container">
        <iframe
          width="100%"
          height="650"
          src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJlfDbEcb0zYURzqh73YQydDo&key=AIzaSyAXBL84N2KiTjAIhyxLD1YewSS2EEKZolc"
        ></iframe>
      </section>
      <section>
      
      </section>


    </>
  );
}

export default FrontPage