import './App.css';
import { Route, Routes } from "react-router-dom";
import FrontPage from './pages/FrontPage';


function App() {
  return (
    <div className="App">
      <FrontPage />
    </div>
  );
}

export default App;
