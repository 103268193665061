import React from 'react'

const GridTemplate = () => {
  return (
    <div class="grid grid-cols-6 grid-rows-5 gap-y-0  md:gap-y-4 gap-x-0 md:gap-x-4 md:p-10">
    <div class="col-span-2 row-span-3 max-h-[350px]"><img
              className="h-full w-full object-cover object-center rounded-none md:rounded-xl"
              src={"../assets/images/Jardin.jpg"}
              alt=""
            /></div>
    <div class="col-span-4 row-span-3 max-h-[350px]"><img
              className="h-full w-full object-cover object-center rounded-none md:rounded-xl"
              src={"../assets/images/j3.jpg"}
              alt=""
            /></div>
    <div class="col-span-2 row-span-2"><img
              className="h-full w-full object-cover object-center rounded-none md:rounded-xl"
              src={"../assets/images/j-1.jpg"}
              alt=""
            /></div>
    <div class="col-span-2 row-span-2"><img
              className="h-full w-full object-cover object-center rounded-none md:rounded-xl"
              src={"../assets/images/Jardin.jpg"}
              alt=""
            /></div>
    <div class="col-span-2 row-span-2"><img
              className="h-full w-full object-cover object-center rounded-none md:rounded-xl"
              src={"../assets/images/S-2.jpg"}
              alt=""
            /></div>
    </div>
  )
}

export default GridTemplate