import React, {Component} from 'react';
import ReactLogo from '../assests/images/Logo.svg';



const MenuLogo = () =>{
    return(
        <div className="nav-logo-box mx-3 h-3/4">
            <a className="navbar-brand" href="/" >
                <img src={ReactLogo} className='h-full' alt="Logo QuintaAtenco"></img>
            </a>
        </div>

    );

};


function MenuItems() {

    let menu = ["Inicio", "Servicios", "Eventos", "Paquetes", "Contactos", "Ubicación"];

    return (
        <div className="navbar navbar-expand-lg navbar-light block" id="navbarNav">
            <ul className="navbar-nav gap-2">
                {menu.map((item, index) => {
                    return <li key={index} className="nav-item">
                        <a className="nav-link" href={`#${item}`}>{item}</a>
                    </li>;
                })}
            </ul>
        </div>
    );
}


const MenuSocialItems = () =>{
    return(
        <div className="social-box-menu">
            <ul className="navbar-nav">
                <li className="nav-item d-inline-flex align-items-center">
                <i className="bi bi-facebook"></i>
                <a className="nav-link mx-2" href="https://github.com">@salon_quinta_atenco</a>
                </li>
                <li className="nav-item d-inline-flex align-items-center">
                    <i className="bi bi-instagram"></i>
                    <a className="nav-link mx-2" href="https://github.com">@Quinta_antenco</a>
                </li>
            </ul>
        </div>
    );
}

const Navbar = () => {
    return (
        <React.Fragment>
            <nav className="hidden md:flex flex-row justify-content-between h-16">
                <MenuLogo />
                <MenuItems />
                <MenuSocialItems />
            </nav>
        </React.Fragment>
    );
};

export default Navbar;
