import React,{Component} from 'react';

class BarButton extends React.Component {
    render(){
        return(<React.Fragment>

            <div className="span-Bar fixed-bottom">
                <button className="btn btn-outline-dark button-type1 my-2">
                    <h4>Haz una Cita</h4>
                </button>
            </div>



        </React.Fragment>

        )
    }


}


export default BarButton;