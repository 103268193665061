import React,{Component} from 'react';



export default class BoxCard extends Component{
    constructor(props){
        super(props);
        this.state = {
            digit: 0
        }
    }

    componentDidMount = () =>{


    }

    render(){
        return( <React.Fragment>
           
  
        </React.Fragment> 
        ) 
    }


};
