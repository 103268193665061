import React,{Component} from "react";
import LazyLoad from "react-lazyload";
import "../assests/css/carosuel.css";
import frontPageImages from "../assests/templates/frontPageImages";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from "swiper/modules";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


class CarouselImage extends Component {


  render(){
    return (
      <>
      <Swiper
        pagination={{
          type: 'progressbar',
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper"
      >
        {frontPageImages.map((item,index)=>{
          return(
            <SwiperSlide>
              <LazyLoad>
                <img key={index} className="banner-image" src={item.image}/>
            </LazyLoad>
          </SwiperSlide>)
        })}
      </Swiper>
    </>
    );
  }
}

export default CarouselImage;